<template>
  <section class="card">
    <link rel="stylesheet" type="text/css" href="/assets/css/preferencias.css">

    <data-header :handleTrocarVisao="handleTrocarVisao"
                 :filtros="filtros"
                 :itemSelecionado="itemSelecionado"
                 :actionButtons="actionButtons">
      <template slot="header">
        <h4>
          Configurações e preferências
        </h4>
      </template>
    </data-header>
    <div class="row main-row">
      <div class="col-12">
        <div class="card-body">
          <form id="formPreferencias" v-if="obj" method="post" action="/configuracoes/preferencias" enctype="multipart/form-data">
            <div class="validation-message" v-if="$root.errors && $root.errors.length">
              <ul style="display: block;">
                <li v-for="p in $root.errors"  :key="p">
                  <label id="fullname-error" class="error" for="fullname" style="">{{ p }}</label>
                </li>
              </ul>
            </div>
            <h4 class="text-primary">Geral</h4>
            <div class="row form-group">
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="col-form-label">Nome</label>
                  <input type="text" class="form-control form-control-sm" v-model="obj.Pessoa.Nome" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="col-form-label">Apelido/Nome fantasia</label>
                  <input type="text" class="form-control form-control-sm" v-model="obj.Pessoa.ApelidoRazaoSocial" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">CPF/CNPJ</label>
                    <input type="text" class="form-control form-control-sm" disabled v-model="obj.Pessoa.CpfCnpj" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-lg-3">
                <div class="form-group">
                  <div class="form-group">
                    <label class="col-form-label">Logo</label>
                    <div class="row">
                      <div class="col">
                        <input type="file" @change="readURL" class="form-control form-control-sm" name="File" id="file" accept=".jpg,.jpeg,.png" />
                        <input type="hidden" v-model="obj.PathLogo" />
                      </div>
                      <div class="col">
                        <div v-if="obj.PathLogo">
                          <img style="width:100%" :src="obj.PathLogo" />
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="col-form-label">Codigo Empresa(integração)</label>
                  <input type="text" class="form-control form-control-sm" v-model="obj.IntegracaoCodigoEmpresa" />
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label class="col-form-label">Codigo Revenda(integração)</label>
                  <input type="text" class="form-control form-control-sm" v-model="obj.IntegracaoCodigoRevenda" />
                </div>
              </div>
            </div>
            <h4 class="text-primary">Regras</h4>
            <div class="row form-group">
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="col-form-label">Dias garantia</label>
                  <input type="number" class="form-control form-control-sm" v-model="obj.DiasGarantia" />

                </div>
              </div>
              <div class="col-lg-2">
                <div class="form-group">
                  <label class="col-form-label">Validade avaliação(em dias)</label>
                  <input type="number" class="form-control form-control-sm" v-model="obj.ValidadeAvaliacao" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/services/api.js';
import consultar from '@/services/consultar.js';

//usuarios
export default {

    components: {
        ...consultar.components,

    },
    data() {
        const that = this;
        return {
            ...consultar.data(),
            url: '/configuracoes/preferencias',
            clone: '',
            obj: null,
            actionButtons: [
                {
                    text: 'Salvar alterações',
                    className: 'salvar padrao',
                    icon: 'fas fa-save',
                    color: 'primary',
                    visible: true,
                    title: 'Salvar alterações',
                    callback: that.salvar
                },
                {
                    text: 'Cancelar',
                    className: 'btn-cancelar padrao',
                    icon: '',
                    iconColor: '',
                    color: 'danger',
                    visible: true,
                    title: 'Cancelar alterações',
                    callback: that.cancelar
                },
            ],
            filtros:
            {
                //visao: [
                //    {
                //        id: 0,
                //        title: 'Preferencias da empresa',
                //        filtro: [
                            
                //        ]
                //    },
                //],
                visaoSelecionada: 0,
                visible: true,
                data: []
            },
            
            
            itemSelecionado: [0]

        };
    },

    methods: {
        ...consultar.methods,
        callback: function (request) {
            this.itemSelecionado.push(0);
            this.obj = request.data;
        },
        salvar: function () {
            this.$root.errors = [];
            let form_data = new FormData();
            let that = this;

            for (var key in this.obj) {
                form_data.append(key, this.obj[key]);
            }
            form_data.append('Nome', this.obj.Pessoa.Nome);
            form_data.append('ApelidoRazaoSocial', this.obj.Pessoa.ApelidoRazaoSocial);

            var imagefile = document.querySelector('#file');
            if (imagefile.files.length) {
                form_data.append("LogoFile", imagefile.files[0]);
            }
            
            api
                .post(this.url, form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }})
                .then(response => {
                    if (response.data.success) {
                        that.PathLogo = response.data.obj   
                    }
                });

        },
        readURL(event) {
            let input = event.target;
            let that = this;
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    that.obj.PathLogo = e.target.result;
                }

                reader.readAsDataURL(input.files[0]); // convert to base64 string
            }
        },


    },
    mounted: consultar.mounted,


    beforeMount: function () {
    }
};
</script>
